/* b8f0ec3a92f042530f3c22571046a901308fefef
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetLegalQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetLegalQuery = (
  { __typename?: 'Query' }
  & { legal: (
    { __typename?: 'LegalDocuments' }
    & Pick<Types.LegalDocuments, 'termsOfService' | 'privacyPolicy'>
  ) }
);


export const GetLegalDocument = gql`
    query GetLegal {
  legal {
    termsOfService
    privacyPolicy
  }
}
    `;

/**
 * __useGetLegalQuery__
 *
 * To run a query within a React component, call `useGetLegalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLegalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLegalQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLegalQuery(baseOptions?: Apollo.QueryHookOptions<GetLegalQuery, GetLegalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLegalQuery, GetLegalQueryVariables>(GetLegalDocument, options);
      }
export function useGetLegalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLegalQuery, GetLegalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLegalQuery, GetLegalQueryVariables>(GetLegalDocument, options);
        }
export type GetLegalQueryHookResult = ReturnType<typeof useGetLegalQuery>;
export type GetLegalLazyQueryHookResult = ReturnType<typeof useGetLegalLazyQuery>;
export type GetLegalQueryResult = Apollo.QueryResult<GetLegalQuery, GetLegalQueryVariables>;