import { FC, SVGProps } from "react";

export const Logo: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    viewBox="0 0 44 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.2902 8.72217C5.80473 7.96878 6.48398 7.00772 7.3562 7.2139L20.8865 10.4122C21.8672 10.644 21.9224 12.019 20.9634 12.3286L16.2678 13.8449C15.8481 13.9804 15.5676 14.3759 15.5784 14.8169L15.704 19.9241C15.7287 20.9298 14.4166 21.3335 13.8717 20.4879L6.2902 8.72217Z"
      fill="currentColor"
    />
    <path
      d="M35.634 13.4956C37.433 13.0135 38.3631 11.0034 37.2582 9.50391C33.9044 4.95268 28.507 2 22.4198 2C18.1003 2 14.1282 3.48679 10.9872 5.97649L20.2414 8.16399C20.9487 8.03914 21.6768 7.974 22.4198 7.974C26.1909 7.974 29.5703 9.65122 31.8526 12.3004C32.7316 13.3206 34.0781 13.9125 35.3786 13.5639L35.634 13.4956Z"
      fill="currentColor"
    />
    <path
      d="M9.974 20.4198C9.974 19.7619 10.0249 19.1159 10.1233 18.4855L5.99347 12.0764C4.71855 14.5813 4 17.4166 4 20.4198C4 30.5928 12.2469 38.8396 22.4198 38.8396C30.3284 38.8396 36.0883 34.1887 38.699 27.1904C39.2219 25.7888 39.1309 24.0443 37.8873 23.6227L21.4537 16.4374C20.8641 16.1795 20.1745 16.3909 19.8305 16.9349C19.4651 17.5539 19.6353 18.3498 20.222 18.7652L31.4121 26.6887C32.0826 27.1635 32.2499 28.0979 31.7025 28.7105C29.4233 31.2605 26.109 32.8656 22.4198 32.8656C15.5462 32.8656 9.974 27.2935 9.974 20.4198Z"
      fill="currentColor"
    />
  </svg>
);
